module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"backgroundColor":"none","maxWidth":900,"linkImagesToOriginal":false,"disableBgImage":true,"wrapperStyle":"margin: 1.5rem 0;"}},{"resolve":"gatsby-remark-double-brackets-link","options":{"titleToURLPath":"/opt/build/repo/gatsby-theme-code-notes/src/utils/resolve-url.js","stripBrackets":true}}],"remarkPlugins":[null,null,null,[null,{"style":"smart"}],null],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo/site"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-QBT52HLKJW"],"gtagConfig":{"anonymize_ip":false,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../../gatsby-theme-code-notes/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"content/notes","basePath":"/","gitRepoContentPath":"","showDescriptionInSidebar":true,"showThemeInfo":false,"logo":"","openSearch":{"siteShortName":"Gatsby Theme Code Notes Example","siteUrl":"https://code-notes-example.netlify.app","siteTags":"front-end","siteContact":"https://twitter.com/MrMartineau","siteDescription":"A Gatsby theme for storing your code-related notes"},"showDate":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
