import automaterWalkthroughImg from "../../../../assets/images/spotify_timer/automator-walkthrough.png";
import automatorImg from "../../../../assets/images/spotify_timer/automator.png";
import spotifyServicesImg from "../../../../assets/images/spotify_timer/spotify_services.png";
import spotifyPromptImg from "../../../../assets/images/spotify_timer/prompt.png";
import * as React from 'react';
export default {
  automaterWalkthroughImg,
  automatorImg,
  spotifyServicesImg,
  spotifyPromptImg,
  React
};