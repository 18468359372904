export const badgePrimary = {
  borderRadius: '50px',
  color: 'textStrong',
  bg: 'badgeBg',
  textDecoration: 'none',
  px: 2,
  fontWeight: 'normal',
  fontSize: 0,
}
export const badges = {
  primary: badgePrimary,
}
